import React from 'react'
import { Error } from './Error'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

import { useQuery } from '@tanstack/react-query'
import { getSupervisionData } from '../api/apiCalls'

export const Supervision = () => {  
  const { isLoading, error, data } = useQuery(
    {
      queryKey: ['supervision'],
      queryFn: () => getSupervisionData(),
    }
  )

  if (isLoading) return <p>Loading...</p>
  if (error) return <Error />

  const { attributes: supervisionData } = data.data

  const { url: imageUrl } = supervisionData.image.data.attributes.formats.medium

  return (
    <div id="supervision" style={{background: '#f2f2f2'}}>
      <div className="container">
        <div className="row">
        <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>{data ? supervisionData.title : "loading..."}</h2>

              <p style={{textAlign: 'left'}}>
                <ReactMarkdown linkTarget="_blank" rel="noreferrer">{data ? supervisionData.text : "loading..."}</ReactMarkdown>
              </p>
              
            </div>
          </div>

          <div className="col-xs-12 col-md-6">
            <img src={`${imageUrl}`} className="img-responsive" alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}
import React from 'react'
import { Error } from './Error'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { useQuery } from '@tanstack/react-query'

import { getAboutData } from '../api/apiCalls'

export const About = () => {

    const { isLoading, error, data } = useQuery(
        {
          queryKey: ['about'],
          queryFn: () => getAboutData(),
        }
      )

    if (isLoading) return <p>Loading...</p>
    if (error) return <Error />

    const { attributes: aboutData } = data.data
    const { url: imageUrl } = aboutData.image.data.attributes.formats.small

    return (
        <div id="about" className="text-center">
        <div className="container">
            <div className="col-md-8 col-md-offset-2 section-title">
                <h2>{data ? aboutData.title : "loading..."}</h2>

                <p style={{textAlign: 'left'}}>
                    <ReactMarkdown>{data ? aboutData.textOne : "loading..."}</ReactMarkdown>
                </p>
            </div>

            <div id="row">
                <div className="thumbnail">
                    <img src={`${imageUrl}`} className="about-img" />
                </div>
            </div>

            <div id="row">
                <div className="col-md-8 col-md-offset-2 section-title">
                    <h3>{data ? aboutData.titleTextTwo : "loading..."}</h3>

                    <p style={{textAlign: 'left'}}>
                        <ReactMarkdown linkTarget="_blank" rel="noreferrer">{data ? aboutData.textTwo : "loading..."}</ReactMarkdown>
                    </p>

                </div>
            </div>
        
        </div>
        </div>
    )
}
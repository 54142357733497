import React from 'react'
import './App.css'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { Navigation } from "./components/Navigation"
import { Banner } from "./components/Banner"
import { About } from "./components/About"
import { Mediation } from "./components/Mediation"
import { Supervision } from "./components/Supervision"
import { Trainings } from "./components/Trainings"
import { Contact } from "./components/Contact"
import { ContactNoForm } from "./components/ContactNoForm"
import { Impressum } from "./components/Impressum"

const queryClient = new QueryClient()

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Banner/>
        <Navigation />
        <About />
        <Supervision />
        <Mediation />
        <Trainings />       
{/*     <Contact /> */}        
        <ContactNoForm />
        <Impressum />
      </QueryClientProvider>
    </div>
  )
}

export default App

import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL

export function getBannerData(){
  return axios.get(`${API_URL}/banner/?populate=*`)
      .then(response => response.data) 
}

export function getAboutData(){
  return axios.get(`${API_URL}/about/?populate=*`)
      .then(response => response.data) 
}

export async function getSupervisionData(){
  return axios.get(`${API_URL}/supervision/?populate=*`)
      .then(response => response.data) 
}

export async function getMediationData(){
    return axios.get(`${API_URL}/mediation/?populate=*`)
        .then(response => response.data) 
}

export async function getTrainingsData(){
    return axios.get(`${API_URL}/training/?populate=*`)
        .then(response => response.data) 
}
import React from 'react'
import { Error } from './Error'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

import { useQuery } from '@tanstack/react-query'
import { getTrainingsData } from '../api/apiCalls'

export const Trainings = () => {  
    
  const { isLoading, error, data } = useQuery(
    {
      queryKey: ['trainings'],
      queryFn: () => getTrainingsData(),
    }
  )

  if (isLoading) return <p>Loading...</p>
  if (error) return <Error />

  const { attributes: trainingsData } = data.data
  const { url: imageUrl } = trainingsData.image.data.attributes.formats.medium

  return (
    <div id="trainings" style={{background: '#f2f2f2'}}>
      {<div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>{data ? trainingsData.title : "loading..."}</h2>
                <p style={{textAlign: 'left'}}>
                  <ReactMarkdown linkTarget="_blank" rel="noreferrer">{data ? trainingsData.text : "loading..."}</ReactMarkdown>
                </p> 
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <img src={`${imageUrl}`} className="img-responsive" alt="" />
          </div>
        </div>
      </div>}
    </div>
  )
}
import React from 'react'

export const Impressum = () => {
  return(
    <div id="impressum">
      <div className="container">
        <div className="impressum-text">
          <h2>Impressum</h2>
          <h3>Supervision - Mediation - Fortbildungen</h3>

          <div className="list-style">
            <div className="col-lg-6 col-sm-6 col-xs-12">
              <ul>
                <li>Ellen Hupfeld</li>
                <li>Arnsburgerstr. 27</li>
                <li>60385 Frankfurt am Main</li>
                <li>Tel. +49 173 9012875</li>
              </ul>
            </div>
            <div className="col-lg-6 col-sm-6 col-xs-12">
              <ul>
                <li>E-Mail: <a href= "mailto:ellen-hupfeld@gmx.de">ellen-hupfeld@gmx.de</a></li>
                <li>Internet: <a>www.ellen-hupfeld.de</a></li>
                <li>Steuernummer: 14 829 63032</li>
                <li>Kleinunternehmerin nach § 19 UStG</li>
              </ul>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
import React from 'react'

export const ContactNoForm = () => {
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="section-title">
            <h2>Kontakt</h2>
            <p style={{textAlign:'left'}}>
              Bei Interesse an Supervision, Mediation oder Fortbildungen schreiben Sie mir bitte 
              eine E-Mail oder melden Sie sich telefonisch.
            </p>
          </div>

          <div className="contact-item">
            <p>
              <span>
                <i className="fa fa-envelope-o"></i> <a href= "mailto:ellen-hupfeld@gmx.de" style={{color: 'white'}}>Email</a>
              </span>{" "}
              <a href= "mailto:ellen-hupfeld@gmx.de" style={{color: 'white'}}>ellen-hupfeld@gmx.de</a>
            </p>
          </div>

          <div className="contact-item">
            <p>
              <span>
                <i className="fa fa-phone"></i> Telefon
              </span>{" "}
              +49 173 9012875
            </p>
          </div>

          <div className="contact-item">
            <p>
              <span>
                <i className="fa fa-map-marker"></i> Adresse
              </span>
              Arnsburgerstr. 27, 60385 Frankfurt am Main
            </p>
          </div>

        </div>
      </div>
          
    </div>
  )
}

import React from 'react'
import { Error } from './Error'
import { ReactMarkdown } from "react-markdown/lib/react-markdown"
import { useQuery } from '@tanstack/react-query'
import { getBannerData } from '../api/apiCalls'

export const Banner = () => {

  const { isLoading, error, data } = useQuery(
    {
      queryKey: ['banner'],
      queryFn: () => getBannerData(),
    }
  )

  if (isLoading) return <p>Loading...</p>
  if (error) return <Error />

  const { attributes: bannerData } = data.data

  const { url: imageUrl } = bannerData.image.data.attributes.formats.large
  
  return (
    <header id="header">     
      <div className="intro" style={{
        background: `url(${imageUrl}) center center no-repeat`, 
        backgroundSize: 'cover'}}
        >
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 intro-text">
                <h2 style={{color: '#ffffff'}}>
                  {data ? bannerData.title : "Loading"}
                  <span></span>
                </h2>
                
                <ReactMarkdown>
                  {data ? bannerData.description : "Loading"}
                </ReactMarkdown>
                
                <a
                  href="#about"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Mehr erfahren
                </a>{" "}

                <p style={{fontSize: 18, marginTop: 120}}>
                {data ? bannerData.quote : "Loading"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

import React from "react"
import { Error } from './Error'
import { ReactMarkdown } from "react-markdown/lib/react-markdown"

import { useQuery } from '@tanstack/react-query'
import { getMediationData } from "../api/apiCalls"

export const Mediation = () => {  
    
    const { isLoading, error, data } = useQuery(
      {
        queryKey: ['mediation'],
        queryFn: () => getMediationData(),
      }
    )

    if (isLoading) return <p>Loading...</p>
    if (error) return <Error />

    const { attributes: mediationData } = data.data

    const { url: imageUrl } = mediationData.image.data.attributes.formats.medium

  return (
    <div id="mediation">
      {<div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <img src={`${imageUrl}`} className="img-responsive" alt="" />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>{data ? mediationData.title : "loading..."}</h2>

              <p style={{textAlign: 'left'}}>
                <ReactMarkdown linkTarget="_blank" rel="noreferrer">{data ? mediationData.text : "loading..."}</ReactMarkdown>
              </p>
              
            </div>
          </div>
        </div>
      </div>}
    </div>
  )
}
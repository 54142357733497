import React from 'react'

/* TODO: make nicer looking ! */
export const Error = () => {  

  return (
    <div id={"error"}>
      <div className="container">
        <div className="row">
        <h2>Fehler beim Laden der Website-Daten...</h2>
        </div>
      </div>
    </div>
  )
}